/* eslint-disable */

import axios from 'axios';
import api from "../../api/api";

window.DataLayer = function(conf, map) {
  this.conf = conf;

  this.set("url", conf.url);
  this.set("map_param", map);
  this.set("layerName", conf.name);
  this.set("style", conf.style);
  this.set("visible", conf.visible);
  this.set("minZoomLevel", conf.minZoomLevel);
  this.set("maxZoomLevel", conf.maxZoomLevel);
  this.set("minZoomLevel", conf.minZoomLevel);
  this.set("maxZoomLevel", conf.maxZoomLevel);
  this.set("idField", conf.idField);
  this.set("infoFields", conf.infoFields);
  this.set("infoTemplates", conf.infoTemplates);
  this.set("overInfoFields", conf.overInfoFields);
  this.set("overInfoTemplate", conf.overInfoTemplate);
  this.set('mc', '');
  this.set('clusters', '');
  this.set("maxZoomClusteringLevel", conf.maxZoomClusteringLevel);
  this.set("data");
  var originalFeatures;
  this.getOriginalFeatures = function () {
    return originalFeatures;
  };
  this.setOriginalFeatures = function (features) {
    originalFeatures = features;
  };
  google.maps.Data.call(this);

  this.setStyle(this.styling());

  var this_layer = this;

  if (conf && conf.maxZoomClusteringLevel) {
    this.createCluster(conf, map);
  }

  if (conf && conf.adaptativeMarkers) {
    google.maps.event.addListener(map, 'zoom_changed', function() {
      if (this_layer.visible === true || this_layer.get("maxZoomClusteringLevel")) {
        this_layer.setStyle(this_layer.styling());
        this_layer.setVisibilityByZoom();
      }
    });
  }

  if (conf.minZoomLevel || conf.maxZoomLevel) {
    google.maps.event.addListener(map, 'zoom_changed', function() {
      if (this_layer.visible === true || this_layer.get("maxZoomClusteringLevel"))
        this_layer.setVisibilityByZoom();
    });
  }

  if (conf.displayInfo) {
    google.maps.event.addListener( this_layer, 'click', function (event) {
      conf.callback.displayInfo(event.feature, this.conf);
    });
  }

}

util.inherits(DataLayer, google.maps.Data);

DataLayer.layers = {}

DataLayer.prototype.createCluster = function(conf, map) {
  var this_layer = this;
  var unlock_layer = false;
  var timerId_layer = setInterval(function(){
    if(unlock_layer){
      clearInterval(timerId_layer);
      return;
    }
    else {
      if (this_layer && this_layer.getAllFeatures().length>0){
        unlock_layer = true;
        if (this_layer.getAllFeatures()[0].getGeometry().getType() === "Point"){
          this_layer.hide();
          var markerIcons = require(`@/assets${conf.style.path}/${conf.style.clusterMarker}`);
          var markerIcon = require(`@/assets${conf.style.path}/${conf.style.marker}`);
          var markerStyle =  [{
            url: markerIcons,
            height: conf.clusterIconSize[1],
            width: conf.clusterIconSize[0],
            anchor: conf.clusterAnchor,
            fontWeight: conf.clusterFontWeigth ,
            textColor: conf.clusterTextColor,
            textSize: conf.clusterTextSize
          }];
          var mcOptions = {
            cssClass: 'clustericon',
            gridSize: conf.clusterGridSize || 60,
            zoomOnClick: true,
            maxZoom: this_layer.get("maxZoomClusteringLevel") || 14,
            styles: markerStyle,
          };
          var mc = new MarkerClusterer(map, [], mcOptions);
          for (var i = 0; i < this_layer.getAllFeatures().length; i++) {
            var e = this_layer.getAllFeatures()[i];
            var geo = e.getGeometry();
            if(geo.getType()==='Point'){
              if (e.getProperty('icon') !== undefined) {
                markerIcon = require(`@/assets${conf.style.path}/${e.getProperty('icon')}`);
              }
              var marker_temp = new google.maps.Marker({
                position:geo.get(),
                icon: markerIcon
              });
              marker_temp.features = e;
              mc.addMarker(marker_temp);
              if (conf.displayInfo) {
                google.maps.event.addListener( marker_temp, 'click', function (event) {
                  conf.callback.displayInfo(this.features, this_layer.conf);
                });
              }
            }
          }
          this_layer.set('mc', mc);
          this_layer.set('cluster', mc.getMarkers());
        }
      }
    }
  },200);
}

DataLayer.prototype.addGeoJsonDataLayer = function (url) {
  var this_datalayer = this;

  var promise = new Promise(function(resolve, reject){
    api.get(url).then(function(response){
      DataLayer.layers[this_datalayer.get('layerName')] = this_datalayer;
      this_datalayer.addGeoJson(response.data,  {idPropertyName: "id"});
      this_datalayer.setMap(this_datalayer.get('map_param'));
      resolve(response);
    }).catch(function(error){
      reject(error);
    });
  });
  return promise;
}

/** Set data layer visibility On.
 * @param
 * @returns
 */
DataLayer.prototype.show = function () {

  this.setMap(this.get('map_param'));

};

/** Set data layer visibility Off.
 * @param
 * @returns
 */
DataLayer.prototype.hide = function () {
  this.setMap(null);
  this.hideClustering();

};

/** Set data clustering layer visibility On.
 * @param
 * @returns
 */
DataLayer.prototype.showClustering = function () {

  var cluster = this.get("mc");
  if (cluster && cluster.getTotalMarkers() == 0) {
    cluster.addMarkers(this.get('cluster'));
    cluster.redraw();
  }
  else if (cluster) {
    cluster.redraw();
  }
};

/** Set data clustering layer visibility Off.
 * @param
 * @returns
 */
DataLayer.prototype.hideClustering = function () {

  var cluster = this.get("mc");
  if (cluster) cluster.clearMarkers();
};


/** Get all Features.
 * @param
 * @returns features
 */
DataLayer.prototype.getAllFeatures = function () {

  var features = [];

  this.forEach(function (feature) {
    features.push(feature);
  });

  return features;

};

/** Remove all Features.
 * @param
 * @returns
 */
DataLayer.prototype.removeAllFeatures = function () {

  var dataLayer = this;

  this.forEach(function (feature) {
    dataLayer.remove(feature);
  })

};

/** Add Features.
 * @param features Array of features to add.
 * @returns
 */
DataLayer.prototype.addFeatures = function (features) {

  for (var i = 0; i < features.length; i++) {
    this.add(features[i]);
  };

};

/** Set data layer active/deactive.
 * @param state True/False to activate/deactivate the layer
 * @returns
 */
DataLayer.prototype.activate = function (state) {

  this.set('visible', state);

};


/** Hide all data layers.
 */
DataLayer.hideAllDataLayers = function () {

  var layers = DataLayer.layers;
  for (var c in layers) {
    layers[c].visible = false;
    layers[c].hide();
  }
};

/** Set data layer visibility On/Off attending zoom level.
 * @param
 * @returns
 */
DataLayer.prototype.setVisibilityByZoom = function () {

  if(!this.get("maxZoomClusteringLevel")){
    if (this.get("maxZoomLevel") && this.get("minZoomLevel")) {
      if (this.get("visible") && this.get('map_param').getZoom() >= this.get("minZoomLevel") && this.get('map_param').getZoom() <= this.get("maxZoomLevel")) {
        this.show();
      }
      else {
        this.hide();
      }
    }
    else if (this.get("maxZoomLevel")) {
      if (this.get("visible") && this.get('map_param').getZoom() <= this.get("maxZoomLevel")) {
        this.show();
      }
      else {
        this.hide();
      }
    }
    else if (this.get("minZoomLevel")) {
      if (this.get("visible") && this.get('map_param').getZoom() >= this.get("minZoomLevel")) {
        this.show();
      }
      else {
        this.hide();
      }
    }
    else {
      if (this.get("visible")) {
        this.show();
      }
      else {
        this.hide();
      }
    }
  }
  else {
    if (this.get("visible") && this.get("maxZoomLevel") && this.get("minZoomLevel")) {
      if ((this.get('map_param').getZoom() > this.get("maxZoomClusteringLevel") ) && (this.get('map_param').getZoom() >= this.get("minZoomLevel") && this.get('map_param').getZoom() <= this.get("maxZoomLevel"))) {
        this.show();
        this.hideClustering();
      }
      else {
        this.hide();
        this.showClustering();
      }
    }
    else if (this.get("visible") && this.get("maxZoomLevel")) {
      if ((this.get('map_param').getZoom() > this.get("maxZoomClusteringLevel") ) && (this.get('map_param').getZoom() <= this.get("maxZoomLevel"))) {
        this.show();
        this.hideClustering();
      }
      else {
        this.hide();
        this.showClustering();
      }
    }
    else if (this.get("visible") && this.get("minZoomLevel")) {
      if ((this.get('map_param').getZoom() > this.get("maxZoomClusteringLevel") ) && (this.get('map_param').getZoom() >= this.get("minZoomLevel"))) {
        this.show();
        this.hideClustering();
      }
      else {
        this.hide();
        this.showClustering();
      }
    }
    else if(this.get("visible")){
      if ((this.get('map_param').getZoom() > this.get("maxZoomClusteringLevel") )) {
        this.show();
        this.hideClustering();
      }
      else {
        this.hide();
        this.showClustering();
      }
    } else {
      this.hide();
      this.hideClustering();
    }
  }
};

/** Crop map to layer bounds.
 */
DataLayer.prototype.cropToBounds = function() {
  var features = this.getAllFeatures();
  var bounds = new google.maps.LatLngBounds();
  features.forEach(function(feature) {
    feature.getGeometry().forEachLatLng(function(latlng){
      bounds.extend(latlng);
    });
  });
  this.get('map_param').fitBounds(bounds);
}

/** Set data layer style.
 * @returns style
 */
DataLayer.prototype.styling = function () {
  var style = this.get('style');
  if (style) {
    const zoomLevel = this.get('map_param').getZoom();
    if (this.conf.adaptativeMarkers && style.path) {
      style.icon = {
        url: require(`@/assets${style.path}/${style.marker}`),
      };
    }
    return style;
  }
  return this.conf.defaultStyle;
};

/** Get data layer.
 * @param layerName Data layer name.
 * @returns {dataLayer}
 */
DataLayer.getDataLayer = function (layerName) {

  var layers = DataLayer.layers;

  for (var c in layers) {
    if (layers[c].layerName === layerName) {
      return layers[c];
    }
  }

  return undefined;

};
